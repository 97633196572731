import React from 'react'
import { useDispatch } from 'react-redux';
import { activarEstudiante, actualizarEstudiante } from '../../../action/estudiante';
import { estudianteFormActualizar, estudianteTablaCredenciales } from '../../../action/router';
import { formatFecha } from '../../../helpers/formatData';

export const RowEstudiante = ({estudiante}) => {
    const dispatch = useDispatch()
    const { nombres, rut, apellidoPaterno, apellidoMaterno, fechaNacimiento, estado } = estudiante;
    
    const handleActivar = () => {
        estudiante.estado = 'true'
        dispatch(actualizarEstudiante(estudiante))
    }

    const handleDesactivar = () => {
        estudiante.estado = 'false'
        dispatch(actualizarEstudiante(estudiante))
    }

    const handleActualizar = () => {
        dispatch(activarEstudiante(estudiante._id));
        dispatch(estudianteFormActualizar());
    }

    const handleCredencial = () => {
        dispatch(activarEstudiante(estudiante._id));
        dispatch(estudianteTablaCredenciales());
    }

    return (
        <tr>
            <td>{rut}</td>
            <td>{`${nombres} ${apellidoPaterno} ${apellidoMaterno}`}</td>
            <td>{formatFecha(fechaNacimiento)}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' onClick={ handleDesactivar } >Activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={ handleActivar } >Inactivo</button>
                        </div>
                }
            </td>
            {/* <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm'>Ficha</button>
                </div>
            </td> */}
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-primary btn-sm' onClick={ handleCredencial }>Credencial</button>
                </div>    
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-warning  btn-sm' onClick={ handleActualizar } >Modificar</button>
                </div>    
            </td>
        </tr>
    )
}
